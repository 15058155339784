@tailwind base;
@tailwind components;
@tailwind utilities;

/* .tooltip:before {
  z-index: 10;
} */

.body {
  touch-action: none;
}

[data-reach-combobox-option][data-highlighted] {
  @apply bg-gray-200 text-black;
}

@layer components {
  .tooltip-custom {
    @apply relative origin-right before:pointer-events-none before:absolute before:left-32 before:z-50 before:w-max before:items-center before:justify-center before:rounded-md before:bg-gray-900 before:py-1 before:px-2 before:text-center before:text-white before:opacity-0 before:transition before:duration-200 before:ease-in-out  before:content-[attr(data-tip)] before:hover:inline-flex before:hover:opacity-100 dark:before:bg-white dark:before:text-gray-800;
  }
  .toggle-menu {
    @apply relative rounded-lg border-2 border-neutral-700 p-4 dark:border-white;
  }
  .toggle-label {
    @apply flex w-full cursor-pointer items-center justify-between;
  }
  .toggle-dot {
    @apply absolute -left-1 -top-1 h-5 w-5 rounded-full bg-gray-400 shadow transition peer-checked:translate-x-full peer-checked:bg-white;
  }
  .toggle-line {
    @apply h-3 w-8 rounded-full bg-gray-500 shadow-inner;
  }

  .hover-item {
    @apply hover:bg-white hover:shadow  dark:hover:bg-white/10;
  }
  .card-item {
    @apply bg-white shadow-md dark:bg-white/5  dark:text-white;
  }
  .my-card-base {
    @apply bg-slate-50  text-slate-900 shadow-md drop-shadow-2xl  backdrop-blur-xl backdrop-saturate-[1.25] backdrop-filter dark:bg-gray-800/60 dark:text-white;
  }
  @media (max-width: theme('screens.md')) {
    .mobile-menu {
      @apply bg-slate-50  text-slate-900 shadow-md drop-shadow-2xl  backdrop-blur-xl backdrop-saturate-[1.25] backdrop-filter dark:bg-gray-800/60 dark:text-white;
    }
  }
  .btn-cta {
    @apply rounded-md bg-slate-900 px-2 py-1 text-sm font-medium text-white  outline-none  dark:bg-white dark:text-black;
  }
  .btn-sec {
    @apply cursor-pointer rounded-md bg-gray-200 px-2 py-1 text-sm  font-medium text-slate-900 shadow-md outline-none  dark:bg-white/10  dark:text-white;
  }

  .my-card {
    @apply my-card-base rounded-lg p-4;
  }
  .icon {
    @apply my-card-base inline-flex h-8 w-8 items-center justify-center rounded border-0 shadow outline-none;
  }
  /*icon active state - apply for popover: bg-slate-900/75 text-white dark:bg-white dark:text-slate-800; */
  .small-icon {
    @apply card-item inline-flex h-7 w-7 cursor-pointer items-center justify-center rounded-full p-1  active:bg-slate-900/75 active:text-white active:dark:bg-white active:dark:text-slate-800;
  }
  .widgets {
    @apply p-2;
  }
  .widget {
    @apply my-card-base inline-flex flex-shrink-0 flex-grow-0  flex-col  items-center justify-center rounded-lg py-2 px-3 outline-none;
  }
  .search {
    @apply my-card flex w-[500px] items-center justify-between rounded-lg;
  }
  .long-card {
    @apply my-card-base h-auto w-[500px] rounded-lg p-2;
  }
  .search-provider {
    @apply inline-flex w-16 items-center justify-between gap-1 rounded-full px-2 py-1 shadow outline  outline-1;
  }
  .search-provider-card {
    @apply w-[200px] rounded-md bg-white p-2 text-slate-800 shadow  dark:bg-gray-800 dark:text-white;
  }
  .input-item {
    @apply rounded-md bg-gray-200 px-2 py-1.5 text-base text-slate-800  outline-none placeholder:text-slate-600 focus:border-blue-400 focus:shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-80 dark:border-gray-600 dark:bg-black/10 dark:text-gray-100 dark:placeholder:text-gray-400  dark:focus:border-blue-500;
  }
  .input-label {
    @apply block text-sm capitalize text-gray-800 dark:text-gray-200;
  }
  .input {
    @apply mt-2 block w-full rounded-md border bg-white px-4 py-2 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:focus:border-blue-300;
  }
  .focus {
    @apply my-card flex w-[320px] flex-col;
  }
  .todo-badge {
    @apply cursor-pointer rounded-md  bg-gray-100   px-2 py-1  text-sm font-medium shadow-inner  outline-none  focus:shadow-md focus:outline-none dark:bg-black/10;
  }

  .todo-list {
    @apply my-card-base flex min-h-[300px] w-[320px] flex-col gap-2 rounded-lg p-2;
  }
  .todo-item {
    @apply card-item  flex w-full flex-col gap-2 rounded-md border-l-[4px]   p-2 shadow;
  }
  .circle {
    @apply flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-br from-emerald-500 to-sky-500  text-white;
  }
  @media (max-width: theme('screens.md')) {
    .nav-item {
      @apply card-item flex h-10 w-full items-center justify-center rounded p-1 hover:cursor-pointer;
    }
  }

  .scrollbar::-webkit-scrollbar {
    @apply w-4;
  }
  /* .scrollbar::-webkit-scrollbar-track {
    @apply rounded-full bg-slate-500 dark:bg-slate-500;
  } */
  .scrollbar::-webkit-scrollbar-thumb {
    @apply bg-zinc-500 dark:bg-slate-300;
    border-radius: 100px;
    border: 4px solid transparent;
    background-clip: content-box;
  }
}
